// 用户上传头像接口(简易版）-- 可用于上传文件
export const upfile = function () {
    return "/upfile/upHeadIcon"
}
// 用户修改自身信息
export const editUserPass = function () {
    return "/user/editUserPass"
}

// 用户获取自身信息
export const getUserInfo = function () {
    return "/admin/getUserInfo"
}

// 团队筛选列表
export const ChildTeamList = function () {
    return "/team/childTeamList"
};

// 团队监控列表
export const getAdminList = function () {
    return "/team/adminList"
};

// 设备-列表
export const DeviceList = function () {
    return "/device/loadPaging"
}

//获取设备保养状态
export const DeviceState = function () {
    return "/device/emptyUpkeep"
}

// 获取所有虚拟护栏数据
export const GetNotFlyArea = function () {
    return "/track/lawrecorder/getNotFlyArea"
}

// 无人机型号表
export const DeviceType = function () {
    return "/device/type"
}

// 电池列表
export const DeviceBatterList = function () {
    return "/device/batter/loadPaging"
}

// 设备保养页面数据
export const DeviceMaintainData = function () {
    return "/device/maintain/load"
}

// 添加无人机设备
export const DeviceAdd = function () {
    return "/device/add"
}

// 编辑无人机设备
export const DeviceUpdate = function () {
    return "/device/update"
}

// 解绑无人机设备
export const DeviceDel = function () {
    return "/device/delete"
}

// 修改设备图片
export const DeviceImage = function () {
    return "/device/deviceImage"
}

// 获取群聊记录
export const GetGroupMsgRecord = function () {
    return "/groupChat/getGroupMsgRecord"
}
// 获取私聊记录
export const getPrivateChatRecord = function () {
    return "/groupChat/getPrivateChatRecord"
}

// 获取团队虚拟护栏列表
export const GetTeamNotFlyList = function () {
    return "/track/lawrecorder/getTeamNotFlyList"
}

// 获取团队虚拟护栏列表---重命名
export const ModifyNotFlyName = function () {
    return "/track/lawrecorder/modifyNotFlyName"
}

// 获取团队虚拟护栏列表---删除
export const DelNotFly = function () {
    return "/track/lawrecorder/delNotFly"
}

// 获取团队虚拟护栏列表---添加虚拟护栏
export const AddNotFlyArea = function () {
    return "/track/lawrecorder/addNotFlyArea"
}

// 获取执法记录仪详情(改版)
export const lawDetail = function () {
    return "/track/lawrecorder/lawDetail"
    // return "/track/lawrecorder/lawDetailPg"
}

// 电池解绑
export const BatterDelete = function () {
    return "/device/batter/delete"
}

// 通用文件上传，如果使用iview-UPLOAD组件原生上传，记得添加name属性
export const UploadFile = function () {
    // return "/Java/upfile/upload" // 开发环境
    return "/jflight/upfile/upload"
}

// // 添加模型
// export const AddModel = function () {
//     return "/resource/model/add";
// }

// // 添加正射
// export const AddYinshe = function () {
//     return "/resource/map/add";
// }

// 获取正射详情
export const MapDetail = function () {
    // return "/mobile/route/mapDetail";
    return `/resource/web/orThoPhotoDetail`;
}
// 全景图 新增
export const PanoramawebAdd = function () {
    return "/panoramaweb/add";
    // return "/jflight/panoramaweb/add";
}
// 全景图 新增场景
export const addPlusImage = function () {
    return '/panoramaweb/addPlusImage';
}
// 全景图 列表
export const PanoramawebList = function () {
    return "/panoramaweb/List";
}

// 全景图 删除
export const PanoramawebDel = function () {
    return "/panoramaweb/delet";
}

// 全景图 修改
export const PanoramawebUpdate = function () {
    return "/panoramaweb/update";
}

// 全景图 组数据
export const PanoramawebDetail = function () {
    return "/panoramaweb/imageListWeb";
}

// 模型图标管理 上传
export const AddModelIcon = function () {
    // return "/Java/app/cloudImageAdd"  // 开发环境
    return "/jflight/app/cloudImageAdd"
}

// 模型图标管理 列表
export const ModelIconList = function () {
    return "/app/getCloudImage";
}

// 模型图标管理 删除
export const DelModelIcon = function () {
    return "/app/deleteCloudImage";
}

// 模型  修改
export const UpdateModel = function () {
    return "/resource/model/update";
}


// 模型  详情
export const InfoModel = function () {
    return "/resource/model/selectByIdData3D";
}

//----------------------------事件----------------------------
// 获取事件类型
export const IncidentTypeList = function () {
    return `/event/type/list`
}
// 类型添加
export const IncidentTypeAdd = function () {
    return `/event/type/add`
}
// 类型删除
export const IncidentTypeDelete = function () {
    return `/event/type/delete`
}
// 类型修改
export const IncidentTypeUpdate = function () {
    return `/event/type/update`
}
// 获取事件列表
export const IncidentList = function () {
    return `/event/list`
}
// 类型添加
export const IncidentAdd = function () {
    return `/event/add`
}
// 类型删除
export const IncidentDelete = function () {
    return `/event/delete`
}
// 类型修改
export const IncidentUpdate = function () {
    return `/event/update`
}