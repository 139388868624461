<template>
  <div style="position: relative">
    <header>
      <div @click="save()"
           style="cursor: pointer; font-size: 16px">
        <Icon type="ios-arrow-back"
              style="margin-right: 6px"
              size="22" />保存并返回
      </div>
      <div>
        <!-- <span v-if="$online"
              class="btn"
              @click="shareModel = true">显示名称</span> -->
        <span v-if="$online"
              class="btn"
              @click="shareModel = true">分享</span>
        <span class="btn"
              @click="gohome()">主视角</span>
        <span class="btn"
              @click="showAside()">
          <Icon type="md-menu"
                size="20" />
        </span>
      </div>
    </header>
    <article>
      <div class="title">{{ modelName }}</div>
      <!-- <Input class="title"
               v-model="modelName"
               @on-enter="updateTitle"
               @on-blur="updateTitle"
               placeholder="请输入模型名称" /> -->
    </article>
    <main style="position: relative">
      <div id="cesiumContainer"></div>
      <aside ref="aside">
        <div class="panel"
             ref="panel">
          <!-- 线 -->
          <div v-if="doType === 'polyline' || doType === 'curve'">
            <h3>标注样式</h3>
            <div class="panel-item">
              <span>线条颜色</span>
              <colorPicker v-model="color"
                           v-on:change="headleChangeColor(1)" />
            </div>
            <div class="panel-item">
              <p>线条宽度</p>
              <Slider :max="10"
                      :min="1"
                      v-model="width"
                      @on-change="headleChangeSlider(1)"
                      :step="1"></Slider>
            </div>
          </div>
          <!-- 面 -->
          <div v-else-if="
              doType === 'doubleArrow' ||
              doType === 'attackArrowYW' ||
              doType === 'polygon' ||
              doType === 'closeVurve' ||
              doType === 'rectangle' ||
              doType === 'circle'
            ">
            <h3>标注样式</h3>
            <div class="panel-item">
              <span>填充颜色</span>
              <colorPicker v-on:change="headleChangeColor(1)"
                           v-model="color" />
            </div>
            <div class="panel-item">
              <p>透明度</p>
              <Slider :max="1"
                      :min="0"
                      v-model="opacity"
                      @on-change="headleChangeSlider(2)"
                      :step="0.1"></Slider>
            </div>
            <div class="panel-item">
              <span>描边颜色</span>
              <colorPicker v-model="outlineColor"
                           v-on:change="headleChangeColor(2)" />
            </div>
            <div class="panel-item">
              <span>描边宽度</span>
              <Slider :max="10"
                      :min="1"
                      v-model="width"
                      @on-change="headleChangeSlider(1)"
                      :step="1"></Slider>
            </div>
          </div>
          <!-- 点 -->
          <div v-else-if="doType === 'point'">
            <h3>标注样式</h3>
            <div class="panel-item">
              <span>颜色</span>
              <colorPicker v-model="color"
                           v-on:change="headleChangeColor(1)" />
            </div>
            <div class="panel-item">
              <p>大小</p>
              <Slider :max="30"
                      :min="10"
                      v-model="pixelSize"
                      @on-change="headleChangeSlider(3)"
                      :step="1"></Slider>
            </div>
          </div>

          <!-- <div v-else-if="doType==='label'"> -->
          <div>
            <h3>文本信息</h3>
            <div class="panel-item">
              <Input v-model="labelTxt"
                     maxlength="15"
                     @on-change="headleChangelabel()"
                     show-word-limit></Input>
            </div>
            <div class="panel-item">
              <span>字体颜色</span>
              <colorPicker v-model="labelColor"
                           v-on:change="headleChangeColor(3)" />
            </div>
            <div class="panel-item">
              <p>字体大小</p>
              <Slider :max="80"
                      :min="14"
                      v-model="labelSize"
                      @on-change="headleChangeSlider(4)"
                      :step="1"></Slider>
            </div>
            <div class="panel-item">
              <span>描边颜色</span>
              <colorPicker v-model="labelOutline"
                           v-on:change="headleChangeColor(4)" />
            </div>
            <div class="panel-item">
              <p>文本高度</p>
              <Slider :max="200"
                      :min="0"
                      v-model="labelOffset"
                      @on-change="headleChangeSlider(5)"
                      :step="5"></Slider>
            </div>
          </div>
          <div class="btns">
            <!-- <div class="save"
                 @click="updataGraphicLayer()">修改</div>
            <div @click="closeEditPanel()">取消</div> -->
            <div class="save"
                 @click="closeEditPanel()">保存</div>
          </div>
        </div>
        <div class="tools"
             ref="tools">
          <div class="tools-title">工具</div>
          <!-- <input type="button"
                 class="btn "
                 value="图标点"
                 @click="drawMarker()" /> -->
          <div class="ground-change">
            <span :class="{ ground_active: clampToGround == false }"
                  @click="groundChange(0)">空间标注</span>
            <span :class="{ ground_active: clampToGround == true }"
                  @click="groundChange(1)">贴地标注</span>
          </div>
          <div class="tools-content">
            <div class="tools-item">
              <img @click="draw(1)"
                   :class="{ active: 1 == current }"
                   :src="Icon.dbz"
                   alt="" />
              <span>点标注</span>
            </div>
            <div class="tools-item">
              <img @click="draw(2)"
                   :class="{ active: 2 == current }"
                   :src="Icon.xbz"
                   alt="" />
              <span>线标注</span>
            </div>
            <div class="tools-item">
              <img @click="draw(3)"
                   :class="{ active: 3 == current }"
                   :src="Icon.mbz"
                   alt="" />
              <span>面标注</span>
            </div>

            <div class="tools-item">
              <img @click="draw(4)"
                   :class="{ active: 4 == current }"
                   :src="Icon.ybz"
                   alt="" />
              <span>圆标注</span>
            </div>
            <div class="tools-item"
                 v-if="$CSTJ">
              <img @click="draw(9)"
                   :class="{ active: 4 == current }"
                   :src="Icon.ywjt"
                   alt="" />
              <span>燕尾箭头</span>
            </div>
            <div class="tools-item"
                 v-if="$CSTJ">
              <img @click="draw(10)"
                   :class="{ active: 4 == current }"
                   :src="Icon.qxjt"
                   alt="" />
              <span>钳形箭头</span>
            </div>
          </div>
          <div style="
              width: 197px;
              height: 1px;
              margin: 0 auto;
              background: #707070;
            "></div>
          <div class="tools-content">
            <div class="tools-item">
              <img @click="draw(5)"
                   :class="{ active: 5 == current }"
                   :src="Icon.tbz"
                   alt="" />
              <span>文字</span>
            </div>
            <div class="tools-item">
              <img @click="draw(6)"
                   :class="{ active: 6 == current }"
                   :src="Icon.cbz"
                   alt="" />
              <span>车辆</span>
            </div>
            <div class="tools-item"
                 v-if="$CSXF">
              <img @click="draw(7)"
                   :src="Icon.sbz"
                   alt="" />
              <span>消防栓</span>
            </div>
            <div class="tools-item"
                 v-if="$CSXF">
              <img @click="draw(8)"
                   :class="{ active: 8 == current }"
                   :src="Icon.fbz"
                   alt="" />
              <span>火焰</span>
            </div>
          </div>
        </div>
      </aside>
    </main>

    <Modal v-model="shareModel"
           width="480"
           title="分享链接">
      <div class="shareUrl">
        <p style="margin-bottom: 20px">
          <span class="share-span"
                style="width: 400px">链接：{{ shareUrl }}</span>
          <Icon type="ios-copy"
                title="点击复制"
                style="cursor: pointer"
                size="20"
                @click="copy" />
        </p>
        <vue-qr :text="shareUrl"
                colorDark="#000"
                colorLight="#fff"
                :margin="5"
                style="vertical-align: bottom; margin-right: 20px"
                :size="120"></vue-qr>
        <span class="share-span"
              @click="bigqr = true"
              style="cursor: pointer">点击放大</span>
        <span class="share-note"
              @click="showNote"
              v-if="$CSXF">短信分享</span>
      </div>
    </Modal>
    <Modal v-model="shareNote">
      <span>手机号：</span>
      <Input v-model="phoneNum"
             class="note-input"
             placeholder="请输入手机号">
      </Input>
      <div slot="footer"
           class="footer">
        <Button type="primary"
                @click="compile_ok">确认</Button>
        <Button @click="closeModl">取消</Button>
      </div>
    </Modal>
    <div class="qr"
         v-show="bigqr">
      <Icon type="md-close-circle"
            @click="bigqr = false"
            color="#fff"
            size="40" />
      <vue-qr :text="shareUrl"
              colorDark="#000"
              colorLight="#fff"
              style="display: block"
              :size="400"></vue-qr>
    </div>

    <!-- <div class="anima" >
 <Col class="demo-spin-col" span="8"> -->
    <Spin fix
          v-if="fa">
      <Icon type="ios-loading"
            size="34"
            color="#fff"
            class="demo-spin-icon-load"></Icon>
    </Spin>
    <!-- </Col>
    </div> -->
  </div>
</template>

<script>
import dbz from '@/assets/img/statistics/zst_ (1).png';
import xbz from '@/assets/img/statistics/zst_ (2).png';
import mbz from '@/assets/img/statistics/zst_ (3).png';
import ybz from '@/assets/img/statistics/zst_ (4).png';
import tbz from '@/assets/img/statistics/zst_ (5).png';
import cbz from '@/assets/img/statistics/zst_ (6).png';
import sbz from '@/assets/img/statistics/zst_ (7).png';
import fbz from '@/assets/img/statistics/zst_ (8).png';
import ywjt from '@/assets/img/statistics/zst_ (8).png';
import qxjt from '@/assets/img/statistics/zst_ (8).png';
import vueQr from 'vue-qr';

// import * as turf from '@turf/turf'
// import area from '@turf/area'
// import { polygon, area } from '@turf/turf'; // 一次引入多个
import { UpdateModel, InfoModel } from '@/utils/javaApi.js';
import Api from '@/utils/api.js';
export default {
  beforeDestrioy() {
    window.Viewer_figure.destroy();
    window.Viewer_graphicLayer.destroy(); //销毁
    window.entity = null;
  },

  name: 'CesiumList',

  components: {
    vueQr,
  },

  data() {
    return {
      entity: null, // 当前实体
      doType: '', // 当前实体类型
      labelTxt: '', // 标注文本
      labelSize: 26, // 标注文本字体大小
      labelColor: '#ffffff', // 标注文本颜色,兼文本标注主颜色
      labelOutline: '#000000', // 标注文本描边线颜色
      labelOffset: 60, // 标注文本高度
      color: '#ffff00', // 标注主颜色
      width: 2, // 线标注宽度,兼面标注描边线宽度
      opacity: 0.5, // 面标注透明度
      outlineColor: '#ffffff', // 面标注描边颜色
      pixelSize: 16, // 点标注大小

      modelName: '',
      modelPath: '',

      tiles3dLayer: '',
      graphicLayer: '',

      //   markerImg:{
      //       type:'heart',
      //       url:require('@/assets/love.png'),
      //   },
      type: 'measureDistance',
      image: require('@/assets/pin-red.png'),
      iconPath: '',
      marks: [],

      modelId: undefined,
      Icon: {
        dbz,
        xbz,
        mbz,
        ybz,
        tbz,
        cbz,
        sbz,
        fbz,
        ywjt,
        qxjt,
      },
      clampToGround: false, // 是否贴地
      shareModel: false,
      shareNote: false, //短信分享弹窗
      phoneNum: '', //分享手机号
      shareUrl: '',
      bigqr: false,
      current: 0,
      ondraw: false,
      fa: true,
    };
  },

  mounted() {
    this.modelId = this.$route.query.id;
    this.shareUrl = this.$mainHost + '/cesiumShare.html?id=' + this.modelId;
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    this.teamID = this.userInfo.team_id;
    this.token = sessionStorage.getItem('token');
    this.getDetail();
  },

  methods: {
    goback() {
      this.$router.back(-1);
    },
    gohome() {
      window.tiles3dLayer.flyTo();
      //   window.Viewer_figure.flyToPoint(this.center);
    },
    updateTitle() {
      if (this.modelName == '') {
        this.$Message.warning('三维模型名称不能为空');
      }
    },
    copy() {
      //   console.log(params.row.apk_url);
      let oInput = document.createElement('input');
      oInput.value = this.shareUrl;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      oInput.style.display = 'none';
      this.$Message.success('已复制到剪切板');
      document.body.removeChild(oInput);
    },

    // 切换显示工具栏
    showAside() {
      if (this.$refs['tools'].style.right === '0px') {
        //关闭
        this.$refs['tools'].style.right = '-228px';
        console.log(this.$refs['panel'].style.right === '228px');
        if (this.$refs['panel'].style.right === '228px') {
          this.$refs['panel'].style.right = '0px';
        }
      } else {
        //展开
        this.$refs['tools'].style.right = '0px';
        if (this.$refs['panel'].style.right === '0px') {
          this.$refs['panel'].style.right = '228px';
        }
      }
    },

    async getDetail() {
      let res = await this.$post(InfoModel(), {
        model_id: Number(this.modelId),
      });
      if (res.code === 1) {
        this.marks = JSON.parse(res.data.marks);
        this.modelName = res.data.modelname;
        this.modelPath =
          res.data.path +
          (res.data.model_type == 2
            ? 'Scene/Production_2.json'
            : 'models/pc/0/terra_b3dms/tileset.json');
        let centerPonit= [res.data.lng,res.data.lat]
        this.$store.commit('SET_PitchMapCenter',centerPonit)
        this.init();
      } else {
        this.$Message.error(res.msg_customer);
      }
    },
    // 保存
    async save() {
      if (this.modelName == '') {
        this.$Message.warning('三维模型名称不能为空');
      } else {
        let json = {};
        if (window.Viewer_graphicLayer.length !== 0) {
          json = window.Viewer_graphicLayer.toGeoJSON();
        }
        let res = await this.$post(UpdateModel(), {
          model_id: this.modelId,
          marks: JSON.stringify(json),
          modelName: this.modelName,
        });
        if (res.code === 1) {
          this.$Message.success('标注已保存');
          this.goback();
        } else {
          this.$Message.error('标注保存失败');
        }
      }
    },
    init() {
      let that = this;
      let viewer;
      if (this.$online) {
        viewer = new mars3d.Map('cesiumContainer', {
          scene: {
            center: {
              lat: that.$Center[1],
              lng: that.$Center[0],
              alt: 1700000,
              heading: 0,
              pitch: -90,
            },
            showSun: true,
            showMoon: false,
            showSkyBox: true,
            showSkyAtmosphere: false, //关闭球周边的白色轮廓 map.scene.skyAtmosphere = false
            fog: false,
            fxaa: true,
            globe: {
              // show: false,
              showGroundAtmosphere: false, //关闭大气（球表面白蒙蒙的效果）
              depthTestAgainstTerrain: false,
              baseColor: '#546a53',
            },
            cameraController: {
              zoomFactor: 3.0,
              minimumZoomDistance: 1,
              maximumZoomDistance: 50000000,
              enableRotate: true,
              enableZoom: true,
              // enableCollisionDetection: true, //是否允许 地形相机的碰撞检测  -----对应：进入地下     无效
            },
          },
          basemaps: [
            {
              name: '高德影像',
              icon: 'http://mars3d.cn/img/basemaps/gaode_img.png',
              type: 'group',
              layers: [
                {
                  name: '底图',
                  type: 'gaode',
                  layer: 'img_d',
                },
              ],
              show: true,
            },
            {
              name: '高德影像(含标注)',
              icon: 'http://mars3d.cn/img/basemaps/gaode_img.png',
              type: 'group',
              layers: [
                {
                  name: '底图',
                  type: 'gaode',
                  layer: 'img_d',
                },
                {
                  name: '注记',
                  type: 'gaode',
                  layer: 'img_z',
                },
              ],
              // show: true,
            },
            {
              name: '高德电子',
              icon: 'http://mars3d.cn/img/basemaps/gaode_vec.png',
              type: 'gaode',
              layer: 'vec',
              // show: true,
            },
          ],
          control: {
            baseLayerPicker: true, //basemaps底图切换按钮,同时展示底图
            homeButton: false, //视角复位按钮
            sceneModePicker: false, //二三维切换按钮
            navigationHelpButton: false, //帮助按钮
            fullscreenButton: true, //全屏按钮
            defaultContextMenu: false, //右键菜单
            mouseDownView: true,
            animation: true,

            //   locationBar: {
            //     fps: true,
            //     enable: true,
            //     crs: 'EPSG4326',
            //   },
          },
          terrain: {
            url: that.$public
              ? 'https://data.mars3d.cn/terrain'
              : 'http://data.mars3d.cn/terrain', //正式服

            show: true,
          },
          // terrain: {
          //   requestVertexNormals: false,
          //   show: true,
          //   type: 'xyz',
          //   url: '//data.mars3d.cn/terrain',
          // },
        });
      } else {
        viewer = new mars3d.Map('cesiumContainer', {
          scene: {
            center: {
              lat: that.$Center[1],
              lng: that.$Center[0],
              alt: 1700000,
              heading: 0,
              pitch: -90,
            },
            showSun: true,
            showMoon: false,
            showSkyBox: true,
            showSkyAtmosphere: false, //关闭球周边的白色轮廓 map.scene.skyAtmosphere = false
            fog: false,
            fxaa: true,
            backgroundColor: '#363635', // 天空背景色
            globe: {
              // show: false,
              showGroundAtmosphere: false, //关闭大气（球表面白蒙蒙的效果）
              depthTestAgainstTerrain: false,
              baseColor: '#363635',
            },
            cameraController: {
              zoomFactor: 3.0,
              minimumZoomDistance: 1,
              maximumZoomDistance: 50000000,
              enableRotate: true,
              enableZoom: true,
              // enableCollisionDetection: true, //是否允许 地形相机的碰撞检测  -----对应：进入地下     无效
            },
          },

          control: {
            baseLayerPicker: false, //basemaps底图切换按钮,同时展示底图
            homeButton: false, //视角复位按钮
            sceneModePicker: false, //二三维切换按钮
            navigationHelpButton: false, //帮助按钮
            fullscreenButton: true, //全屏按钮
            defaultContextMenu: false, //右键菜单
            mouseDownView: true,
            animation: true,

            //   locationBar: {
            //     fps: true,
            //     enable: true,
            //     crs: 'EPSG4326',
            //   },
          },
          hasTerrain: false,
          basemaps: [
            {
              name: '长沙卫星地图',
              type: 'xyz',
              // url: Api.TianDiTuMap() + z + "/" + x + "/" + y + ".png",//http://43.4.201.100:9044/map/GaoDe/Satellite/
              // url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
              url: 'http://43.4.201.100:9044/map/TianDiTu/satellite_TMS/{z}/{x}/{y}.png', // $CSTJ
              subdomains: 'abc',
              show: true,
            },
          ],

          // layers: [
          //   {
          //     name: "网格线",
          //     type: "grid",
          //     color: "#ffffff",
          //     alpha: 0.03,
          //     cells: 2,
          //     show: true,
          //   },
          // ],
        });
      }
      window.Viewer_figure = viewer; // 全局注册cesium(不能挂载到data里,数据劫持会造成地图卡顿)
      window.Viewer_figure.viewer.requestRenderMode = false; //是否显示渲染，默认 true,如果为真，渲染帧只会在需要时发生，这是由场景中的变化决定的。启用可以减少你的应用程序的CPU/GPU使用量，并且在移动设备上使用更少的电池，但是需要使用 Scene#requestRender 在这种模式下显式地渲染一个新帧。在许多情况下，在API的其他部分更改场景后，这是必要的。

      window.tiles3dLayer = new mars3d.layer.TilesetLayer({
        name: that.modelName,
        url: that.modelPath,
        // position: { alt: 11.5 },
        maximumScreenSpaceError: 3,
        maximumMemoryUsage: 1024,
        dynamicScreenSpaceError: true,
        cullWithChildrenBounds: false,
        skipLevelOfDetail: true,
        preferLeaves: true,
        flyTo: false,
      });
      window.Viewer_figure.addLayer(window.tiles3dLayer);
      window.Viewer_figure.onlyPickModelPosition = false;
      setTimeout(() => {
        window.tiles3dLayer.flyTo();
        //关掉加载动画
      }, 500);
      setTimeout(() => {
        that.fa = false;

        //关掉加载动画
      }, 3500);
      //   this.$nextTick(() => {
      //     console.log(window.tiles3dLayer);
      //     this.gohome();
      //   });
      //   window.tiles3dLayer.flyTo();

      window.Viewer_graphicLayer = new mars3d.layer.GraphicLayer({
        hasEdit: true,
        isAutoEditing: true, //绘制完成后是否自动激活编辑
      });
      window.Viewer_figure.addLayer(window.Viewer_graphicLayer);

      // 加载历史标注
      window.Viewer_graphicLayer.loadGeoJSON(this.marks, {
        clear: true,
        flyTo: false,
      });

      // 开始编辑
      window.Viewer_graphicLayer.on(mars3d.EventType.editStart, function (e) {
        that.openEditPanel(e.graphic);
      });
      window.Viewer_graphicLayer.on(mars3d.EventType.editStop, function () {
        console.log('停止编辑');
        that.closeEditPanel();
      });
      window.Viewer_graphicLayer.on(
        mars3d.EventType.removeGraphic,
        function () {
          console.log('删除了对象');
          that.closeEditPanel();
        }
      );

      let defaultContextmenuItems =
        window.Viewer_figure.getDefaultContextMenu();
      defaultContextmenuItems[0] = {
        text: '显示此处经纬度',

        show: function (e) {
          return Cesium.defined(e.cartesian);
        },
        callback: function (e) {
          let mpt = mars3d.LatLngPoint.fromCartesian(e.cartesian);
          that.$Modal.info({
            title: '当前位置信息',
            content:
              '<p>经度:' +
              mpt._lng +
              '</p><p>纬度:' +
              mpt._lat +
              '</p><p>海拔:' +
              mpt._alt +
              '</p>',
          });
        },
      };
      defaultContextmenuItems.splice(1, 1); // 删除查看视角信息
      defaultContextmenuItems.splice(2, 2); // 删除三维模型和地形服务(三角网)
      defaultContextmenuItems.splice(3, 1); // 删除标注
      //   defaultContextmenuItems[3].children.splice(3, 1); // 圆形标记(因为无法删除)
      defaultContextmenuItems[3].children.splice(6, 10); // 删除泛光、亮度、夜視、黑白、拾取高亮
      defaultContextmenuItems[4].children.splice(0, 1); // 删除深度監測
      defaultContextmenuItems[4].children.splice(2, 1); // 删除开启大气渲染

      window.Viewer_figure.bindContextMenu(defaultContextmenuItems);

      //在layer上绑定右键菜单
      window.Viewer_graphicLayer.bindContextMenu([
        // {
        //   text: '停止编辑',
        //   iconCls: 'fa fa-trash-o',
        //   callback: function (e) {
        //     let graphic = e.graphic;
        //     if (graphic) {
        //       that.graphicLayer.removeGraphic(graphic);
        //     }
        //   },
        // },
        {
          text: '删除对象',
          iconCls: 'fa fa-trash-o',
          callback: function (e) {
            let graphic = e.graphic;
            if (graphic) {
              window.Viewer_graphicLayer.removeGraphic(graphic);
            }
          },
        },
        {
          text: '计算长度',
          iconCls: 'fa fa-medium',
          show: function (e) {
            let graphic = e.graphic;
            return (
              graphic.type === 'polyline' ||
              graphic.type === 'curve' ||
              graphic.type === 'polylineVolume' ||
              graphic.type === 'corridor' ||
              graphic.type === 'wall'
            );
          },
          callback: function (e) {
            let graphic = e.graphic;
            let strDis = mars3d.MeasureUtil.formatDistance(graphic.distance);
            // alert('该对象的长度为:' + strDis);
            that.$Modal.info({
              content: '<p>该对象的长度为' + strDis + '</p>',
            });
          },
        },
        {
          text: '计算周长',
          iconCls: 'fa fa-medium',
          show: function (e) {
            let graphic = e.graphic;
            return (
              graphic.type === 'circle' ||
              graphic.type === 'rectangle' ||
              graphic.type === 'polygon' ||
              graphic.type === 'closeVurve'
            );
          },
          callback: function (e) {
            let graphic = e.graphic;
            let strDis = mars3d.MeasureUtil.formatDistance(graphic.distance);
            that.$Modal.info({
              content: '<p>该对象的周长为' + strDis + '</p>',
            });
          },
        },
        {
          text: '计算面积',
          iconCls: 'fa fa-reorder',
          show: function (e) {
            let graphic = e.graphic;
            return (
              graphic.type === 'circle' ||
              graphic.type === 'rectangle' ||
              graphic.type === 'polygon' ||
              graphic.type === 'closeVurve'
            );
          },
          callback: function (e) {
            let graphic = e.graphic;
            let strArea = mars3d.MeasureUtil.formatArea(graphic.area);
            that.$Modal.info({
              content: '<p>该对象的面积为' + strArea + '</p>',
            });
          },
        },
      ]);

      //加载的事件 只执行一次
      //   tiles3dLayer.on(mars3d.EventType.initialTilesLoaded, function (event) {
      //     console.log('触发initialTilesLoaded事件', event);
      //   });

      //   //会执行多次，重新加载一次完成后都会回调
      //   tiles3dLayer.on(mars3d.EventType.allTilesLoaded, function (event) {
      //     console.log('触发allTilesLoaded事件', event);
      //   });
    },
    draw(type) {
      this.ondraw = true;
      //   this.current = type;
      switch (type) {
        case 1:
          this.drawPoint();
          break;
        case 2:
          this.drawPolyline();
          break;
        case 3:
          this.drawPolygon('polygon');
          break;
        case 4:
          this.drawEllipse();
          break;
        case 5:
          this.drawLabel();
          break;
        case 6:
          this.drawModel(3);
          break;
        case 7:
          this.drawModel(5);
          break;
        case 8:
          this.drawMarker();
          break;
        case 9:
          this.drawPolygon('attackArrowYW');
          break;
        case 10:
          this.drawPolygon('doubleArrow');
          break;
        default:
          break;
      }
    },
    // 点
    drawPoint() {
      window.Viewer_graphicLayer.startDraw({
        type: 'point',
        style: {
          pixelSize: 12,
          color: '#3388ff',
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: '点标记',
            font_size: 18,
            color: '#ffffff',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: -20,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },
    // 图标点
    drawMarker() {
      window.Viewer_graphicLayer.startDraw({
        type: 'billboard',
        style: {
          image: require('@/assets/fire4.png'),
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: '火焰',
            font_size: 18,
            color: '#f00',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: -20,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },
    // 文本
    drawLabel() {
      window.Viewer_graphicLayer.startDraw({
        type: 'label',
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        style: {
          text: '默认文本',
          color: '#0081c2',
          font_size: 25,
          outline: true,
          outlineColor: '#ffffff',
          outlineWidth: 2,
          pixelOffsetY: -60,
          font_family: '微软雅黑',
        },
      });
    },
    // 线
    drawPolyline() {
      window.Viewer_graphicLayer.startDraw({
        type: 'polyline',
        // maxPointNum: 2,  //限定最大点数，可以绘制2个点的线，自动结束
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        style: {
          color: this.clampToGround ? '#ffff00' : '#3388ff',
          width: 3,
          clampToGround: this.clampToGround,
          label: {
            text: this.clampToGround ? '线标记(贴地)' : '线标记(不贴地)',
            font_size: 18,
            color: '#ffffff',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: -20,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },
    // 曲线
    drawCurve(clampToGround) {
      window.Viewer_graphicLayer.startDraw({
        type: 'curve',
        style: {
          color: clampToGround ? '#ffff00' : '#3388ff',
          width: 3,
          clampToGround: clampToGround,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: clampToGround ? '曲线标记(贴地)' : '曲线标记(不贴地)',
            font_size: 18,
            color: '#ffffff',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: -20,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },
    // 面
    drawPolygon(type) {
      let lab = '';
      switch (type) {
        case 'closeVurve':
          lab = '闭合曲面';
          break;
        case 'polygon':
          lab = this.clampToGround ? '面(贴地)' : '面(不贴地)';
          break;
        case 'attackArrowYW':
          lab = this.clampToGround ? '燕尾箭头(贴地)' : '燕尾箭头(不贴地)';
          break;
        case 'doubleArrow':
          lab = this.clampToGround ? '钳形箭头(贴地)' : '钳形箭头(不贴地)';
          break;
        default:
          break;
      }
      window.Viewer_graphicLayer.startDraw({
        type: type,
        edittype: 'polygon',
        style: {
          color: this.clampToGround ? '#ffff00' : '#3388ff',
          opacity: 0.6,
          outline: true,
          outlineColor: '#ffffff',
          outlineWidth: 2.0,
          clampToGround: this.clampToGround,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: lab,
            font_size: 18,
            color: '#ff0000',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: 0,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },
    // 矩形
    drawRectangle(clampToGround) {
      window.Viewer_graphicLayer.startDraw({
        type: 'rectangle',
        style: {
          color: clampToGround ? '#ffff00' : '#3388ff',
          opacity: 0.6,
          outline: true,
          outlineColor: '#ffffff',
          outlineWidth: 2.0,
          clampToGround: clampToGround,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: '矩形',
            font_size: 18,
            color: '#ff0000',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: 0,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },
    // 圆
    drawEllipse() {
      window.Viewer_graphicLayer.startDraw({
        type: 'circle',
        style: {
          color: this.clampToGround ? '#ffff00' : '#3388ff',
          opacity: 0.6,
          outline: true,
          outlineColor: '#ffffff',
          outlineWidth: 2.0,
          clampToGround: this.clampToGround,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: this.clampToGround ? '圆(贴地)' : '圆(不贴地)',
            font_size: 18,
            color: '#ff0000',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: 0,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },
    // 小模型
    drawModel(type) {
      let gltf = '';
      let lab = '消防车';
      switch (type) {
        case 1:
          gltf =
            'http://data.mars3d.cn/gltf/mars/firedrill/xiaofangyuan-run.gltf';
          lab = '消防员';
          break;
        case 2:
          gltf = 'http://data.mars3d.cn/gltf/mars/firedrill/xiaofangche2.gltf';
          lab = '消防车';
          break;
        case 3:
          gltf =
            'http://data.mars3d.cn/gltf/imap/faba303ffdd04399a9bd8f923ff063a7/gltf/gltf2.gltf';
          lab = '消防车';
          break;
        case 4:
          gltf =
            'http://data.mars3d.cn/gltf/imap/9a4d7fe12b8a49768bf440650ec5dc81/gltf/gltf2.gltf';
          lab = '消防车';
          break;
        case 5:
          gltf =
            'http://data.mars3d.cn/gltf/xiaofang/xiaofangshuan/xiaofangshuan.gltf';
          lab = '消防栓';
          break;
        default:
          break;
      }
      if (this.$CSTJ) {
        gltf = 'http://43.4.201.100:9044/gltf/jingche.gltf';
        lab = '警车';
      }
      window.Viewer_graphicLayer.startDraw({
        type: 'model',
        style: {
          scale: type == 5 ? 0.1 : 1,
          url: gltf,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          label: {
            text: lab,
            font_size: 43,
            color: '#ffffff',
            outline: true,
            outlineColor: '#000000',
            pixelOffsetY: -60,
            distanceDisplayCondition: true,
            distanceDisplayCondition_far: 3000,
            distanceDisplayCondition_near: 0,
          },
        },
      });
    },

    // 展开标注编辑面板
    openEditPanel(e) {
      console.log('开始编辑', e.options);
      window.entity = e; //示例挂载到window对象,避免卡顿
      this.doType = e.options.type;
      if (e.options.type === 'polyline' || e.options.type === 'curve') {
        this.color = e.options.style.color;
        this.width = e.options.style.width;
      } else if (
        e.options.edittype === 'polygon' ||
        e.options.type === 'circle'
      ) {
        this.color = e.options.style.color;
        this.opacity = e.options.style.opacity;
        this.outlineColor = e.options.style.outlineColor;
        this.width = e.options.style.outlineWidth;
      } else if (e.options.type === 'point') {
        this.color = e.options.style.color;
        this.pixelSize = e.options.style.pixelSize;
      }
      if (e.options.type === 'label') {
        this.labelTxt = e.options.style.text;
        this.labelColor = e.options.style.color;
        this.labelOutline = e.options.style.outlineColor;
        this.labelSize = e.options.style.font_size;
        this.labelOffset = e.options.style.pixelOffsetY * -1;
      } else if (e.options.style.label) {
        this.labelTxt = e.options.style.label.text;
        this.labelColor = e.options.style.label.color;
        this.labelOutline = e.options.style.label.outlineColor;
        this.labelOffset = e.options.style.label.pixelOffsetY * -1;
        this.labelSize = e.options.style.label.font_size;
      }

      if (this.$refs['tools'].style.right === '0px') {
        this.$refs['panel'].style.right = '228px';
      } else {
        this.$refs['panel'].style.right = '0px';
      }
    },
    // 关闭标注编辑面板
    closeEditPanel() {
      this.ondraw = false;
      window.entity = null;
      this.$refs['panel'].style.right = '-240px';
      this.doType = ''; // 当前实体类型
      this.labelTxt = ''; // 标注文本
      this.labelSize = 26; // 标注文本字体大小
      this.labelColor = '#ffffff'; // 标注文本颜色,兼文本标注主颜色
      this.labelOutline = '#000000'; // 标注文本描边线颜色
      this.labelOffset = 20; // 标注文本高度
      this.color = '#ffff00'; // 标注主颜色
      this.width = 2; // 线标注宽度,兼面标注描边线宽度
      this.opacity = 0.6; // 面标注透明度
      this.outlineColor = '#ffffff'; // 面标注描边颜色
      this.pixelSize = 16; // 点标注大小
      //     if (this.$refs['tools'].style.right === '0px') {
      //     this.$refs['panel'].style.right = '228px';
      //   } else {
      //     this.$refs['panel'].style.right = '0px';
      //   }
    },
    // 根据不同的标注类型修改标注setOptions
    headleChangeColor(type) {
      let that = this;
      switch (type) {
        case 1:
          window.entity.setOptions({
            style: {
              color: that.color,
            },
          });
          break;
        case 2:
          window.entity.setOptions({
            style: {
              outlineColor: that.outlineColor,
            },
          });
          break;
        case 3:
          if (window.entity.options.type === 'label') {
            window.entity.setOptions({
              style: {
                color: that.labelColor,
              },
            });
          } else if (window.entity.options.style.label) {
            window.entity.setOptions({
              style: {
                label: {
                  color: that.labelColor,
                },
              },
            });
          }
          break;
        case 4:
          if (window.entity.options.type === 'label') {
            window.entity.setOptions({
              style: {
                outlineColor: that.labelOutline,
              },
            });
          } else if (window.entity.options.style.label) {
            window.entity.setOptions({
              style: {
                label: {
                  outlineColor: that.labelOutline,
                },
              },
            });
          }
          break;
        default:
          break;
      }
    },

    headleChangeSlider(type) {
      let that = this;
      switch (type) {
        case 1:
          if (
            window.entity.options.type === 'polyline' ||
            window.entity.options.type === 'curve'
          ) {
            window.entity.setOptions({
              style: {
                width: that.width,
              },
            });
          } else {
            window.entity.setOptions({
              style: {
                outlineWidth: that.width,
              },
            });
          }
          break;
        case 2:
          window.entity.setOptions({
            style: {
              opacity: that.opacity,
            },
          });
          break;
        case 3:
          window.entity.setOptions({
            style: {
              pixelSize: that.pixelSize,
            },
          });

          break;
        case 4:
          if (window.entity.options.type === 'label') {
            window.entity.setOptions({
              style: {
                font_size: that.labelSize,
              },
            });
          } else if (window.entity.options.style.label) {
            window.entity.setOptions({
              style: {
                label: {
                  font_size: that.labelSize,
                },
              },
            });
          }
          break;
        case 5:
          if (window.entity.options.type === 'label') {
            window.entity.setOptions({
              style: {
                pixelOffsetY: Number(that.labelOffset) * -1,
              },
            });
          } else if (window.entity.options.style.label) {
            window.entity.setOptions({
              style: {
                label: {
                  pixelOffsetY: Number(that.labelOffset) * -1,
                },
              },
            });
          }
          break;
        default:
          break;
      }
    },
    headleChangelabel() {
      let that = this;
      if (window.entity.options.type === 'label') {
        window.entity.setOptions({
          style: {
            text: that.labelTxt,
          },
        });
      } else {
        window.entity.setOptions({
          style: {
            label: {
              text: that.labelTxt,
            },
          },
        });
      }
    },
    updataGraphicLayer() {
      let that = this;
      if (
        window.entity.options.type === 'polyline' ||
        window.entity.options.type === 'curve'
      ) {
        window.entity.setOptions({
          style: {
            color: that.color,
            width: that.width,
          },
        });
      } else if (
        window.entity.options.type === 'doubleArrow' ||
        window.entity.options.type === 'attackArrowYW' ||
        window.entity.options.type === 'polygon' ||
        window.entity.options.type === 'closeVurve' ||
        window.entity.options.type === 'circle' ||
        window.entity.options.type === 'rectangle'
      ) {
        window.entity.setOptions({
          style: {
            color: that.color,
            opacity: that.opacity,
            outlineColor: that.outlineColor,
            outlineWidth: that.width,
          },
        });
      } else if (window.entity.options.type === 'point') {
        window.entity.setOptions({
          style: {
            pixelSize: that.pixelSize,
            color: that.color,
          },
        });
      }
      if (window.entity.options.type === 'label') {
        // 文本标注
        window.entity.setOptions({
          style: {
            text: that.labelTxt,
            font_size: that.labelSize,
            color: that.labelColor,
            outline: true,
            outlineColor: that.labelOutline,
            pixelOffsetY: that.labelOffset * -1,
          },
        });
      } else {
        window.entity.setOptions({
          style: {
            label: {
              text: that.labelTxt,
              font_size: that.labelSize,
              color: that.labelColor,
              outline: true,
              outlineColor: that.labelOutline,
              pixelOffsetY: Number(that.labelOffset) * -1,
              //   distanceDisplayCondition: true,
              //   distanceDisplayCondition_far: 3000,
              //   distanceDisplayCondition_near: 0,
            },
          },
        });
      }
    },
    // 切换时候贴地
    groundChange(n) {
      if (this.ondraw == false) {
        this.clampToGround = n == 1 ? true : false;
      }
    },
    //提交手机号
    compile_ok() {
      if (this.phoneNum) {
        const pattern =
          /[a-zA-Z`%~!@#$^&*()=|{}':;',\\\[\]\.<>\/?~！!@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
        let repNum = this.phoneNum.replace(pattern, ',');
        let partitionNum = repNum.split(',');
        this.shareNote = false;
        let params = {
          userIdList: partitionNum,
          livePath: this.shareUrl,
        };
        this.$post(Api.shareNote(), params);
      } else {
        this.$Message.error('请输入手机号');
      }
    },
    //关闭分享弹窗
    closeModl() {
      this.shareNote = false;
    },
    //关闭分享弹窗，打开短信风向弹窗
    showNote() {
      this.shareModel = false;
      this.shareNote = true;
    },
  },
};
</script>

<style
	lang="scss"
	scoped
>
header {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid $black;
  background: $xf_hui1;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $font_color_1;
  font-size: 18px;

  .btn {
    padding: 6px 12px;
    background-color: $xf_hui7;
    color: $font_color_1;
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
    font-size: 14px;
  }
}

article {
  height: 68px;
  position: absolute;
  width: 100%;
  z-index: 3;
  // display: none;
  background-color: $xf_hui1;
  padding: 13px 0.21rem 0;

  .title {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background-color: $black;
    color: $white;
    text-align: center;
    border: 0;
    font-size: 18px;
  }
}

main {
  width: 100%;
  height: calc(100vh - 100px);
  overflow: hidden;

  #cesiumContainer {
    // height: calc(100% - 68px);
    height: 100%;
  }
}

.goback {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 100;
  top: 26px;
  left: 20px;
  background-color: #303336;
  color: #fff;
  padding: 4px;
  border: 1px solid #444;
}

.goback:hover {
  background: #48b;
  border-color: #aef;
  box-shadow: 0 0 8px #fff;
}

aside {
  //   position: absolute;
  //   top: 0;
  //   right: -500px;
  //   width: 468px;
  //   height: 100%;
  .tools {
    position: absolute;
    top: 0;
    right: -228px;
    width: 228px;
    height: 100%;
    background-color: $hui3;
    color: $white;
    transition: right 0.5s;
    z-index: 6;

    .tools-title {
      height: 35px;
      line-height: 35px;
      background: $xf_hui7;
      opacity: 1;
      padding-left: 15px;
    }

    .ground-change {
      padding-top: 15px;
      padding-left: 40px;

      span {
        display: inline-block;
        width: 74px;
        font-size: 12px;
        text-align: center;
        height: 20px;
        line-height: 20px;
        color: $white;
        background: $xf_hui7;
        cursor: pointer;
      }

      .ground_active {
        background-color: $xf_hei2;
      }
    }

    .tools-content {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .tools-item {
        margin: 13px 0;
        width: 60px;
        font-size: 12px;
        cursor: pointer;
        text-align: center;

        img {
          margin: 0 auto 3px;
        }
      }

      .active {
        background-color: #abdcff;
      }
    }
  }

  .panel {
    background-color: $xf_hui1;
    position: absolute;
    top: 0;
    right: -240px;
    width: 240px;
    height: 100%;
    z-index: 5;
    color: $font_color_1;
    transition: right 0.5s;

    > div {
      border-bottom: 1px solid $black;
      padding: 10px 16px;
      margin-bottom: 20px;
    }

    .panel-item {
      margin: 15px 0;
      font-size: 14px;
      font-style: italic;

      span {
        margin-right: 10px;
        vertical-align: text-bottom;
      }
    }

    .btns {
      display: flex;
      justify-content: space-around;
      border-bottom: 0;

      div {
        width: 88px;
        height: 28px;
        background-color: $xf_hui5;
        color: $white;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
      }

      .save {
        background-color: #0773ca;
        width: 200px;
        color: #fff;
      }
    }

    /deep/ .ivu-input {
      background: $xf_hui5 !important;
    }
  }
}

// /deep/.cesium-viewer-fullscreenContainer {
//   top: 0.26rem;
//   left: 1.62rem;
// }
/deep/.cesium-viewer-toolbar {
  top: 1rem;
}

.shareUrl {
  .share-span {
    display: inline-block;
    border: 1px solid $xf_hui7;
    padding: 5px;
    background-color: $xf_hui6;
    margin-right: 12px;
  }

  margin-bottom: 20px;

  .share-note {
    display: flex;
    display: inline;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding: 5px;
    border: 1px solid $xf_hui7;
    color: #c9caca;
    background-color: $xf_hui6;
    cursor: pointer;
  }
}

.footer {
  text-align: right;

  // button {
  //   // width: 88px;
  // }
  button:last-child {
    background-color: $xf_hui7;
    color: $font_color_1;
  }

  button:last-child:hover {
    background-color: $xf_hui7_hover;
  }
}

.qr {
  position: fixed;
  width: 100vw;
  height: 100vw;
  top: 0;
  left: 0;
  z-index: 1800;
  background-color: rgba(0, 0, 0, 0.7);

  img {
    margin: 200px auto 0;
  }

  i {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
}

.note-input {
  width: 250px;
  border-radius: 5px;
  padding: 0 5px;
}

.ivu-spin-fix {
  background-color: #000;
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}
</style>